import {useAudioPlayer} from "@/components/contexts/AudioPlayerContext";
import audioDefaultThumbnail from "@/public/audio-default-thumbnail.jpg";
import {PlayerState} from "@/components/ui/player-state";
import {AudioControlProgress} from "@/components/audioPlayer";
import {PlayerBackward, PlayerForward} from "@/components/svg";
import {IconPlayerPauseFilled, IconPlayerPlayFilled} from "@tabler/icons-react";
import Image from "next/image";
import Link from "next/link";

const AudioControlsBig = () => {
  const {
    metadatas,
    audioSrc,
    isPlaying,
    togglePlay,
    handleBackward,
    handleForward,
  } = useAudioPlayer()

  const {
    illustration = audioDefaultThumbnail.src,
    title = '',
    episode = '',
    presenter = '',
    music = '',
    link = '',
  } = metadatas

  return (
    <>
      <div className='h-full w-full relative' style={{
        background: `hsl(0deg, 0%,66%) url(${illustration}) no-repeat center / cover`,
        backgroundBlendMode: 'screen'
      }}>
        <div className='absolute inset-0 grid place-items-center backdrop-blur-2xl'>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <div className="relative">
                <Image src={illustration} alt="" width="340" height="340" className='h-15 w-15 bg-primary mx-auto'/>
                {!audioSrc && (<PlayerState isOnAir className='absolute top-3 left-3'>En direct</PlayerState>)}
              </div>
              <div className='flex flex-col gap-1'>
                {music && music.title !== "RRB" && (
                  <span className="text-2xl text-foreground-dark truncate">
                    {music && music.title}
                    {" - "}
                    {music && music.artists}
                  </span>
                )}
                {episode && (<span className='text-2xl text-foreground-dark'>{episode}</span>)}
                {title &&
                  link ? (
                    <Link href={link} className='text-sm'>
                      {title}
                    </Link>
                  ) : (
                    <span className='text-sm'>{title}</span>
                  )
                }
                {presenter && (<span className='text-sm'>{presenter}</span>)}
              </div>
            </div>

            <div className='grid place-items-center'>
              <div className='flex items-center gap-6'>
                {audioSrc && <button onClick={handleBackward} title='-15'><PlayerBackward/></button>}
                <button onClick={togglePlay}>{isPlaying ? (<IconPlayerPauseFilled size='32'/>) : (
                  <IconPlayerPlayFilled size='32'/>)}</button>
                {audioSrc && <button onClick={handleForward} title='+15'><PlayerForward/></button>}
              </div>

              {audioSrc && (
                <AudioControlProgress/>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AudioControlsBig